/**
 * @file standalone/colorbox.scss
 *
 * \brief Library file - shared styles for colorbox
 *
 */

@import '../theme-bootstrap';

#colorbox,
#cboxOverlay,
#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

#cboxWrapper {
  max-width: none;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  video {
    width: 100%;
  }
}

#cboxTitle {
  margin: 0;
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}

#colorbox,
#cboxContent,
#cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}
/*
  User Style:
  Change the following styles to modify the appearance of Colorbox.
*/

#cboxOverlay {
  background: $color-overlay--dark;
}

#colorbox {
  outline: 0;
}

#cboxError {
  padding: 50px;
  border: $border;
}

#cboxLoadedContent {
  background: $white;
  padding: 30px 20px 20px 20px;
}

#cboxTitle {
  position: absolute;
  top: -20px;
  left: 0;
  color: #ccc;
}

#cboxCurrent {
  position: absolute;
  top: -20px;
  right: 0px;
  color: #ccc;
}

#cboxLoadingGraphic {
  background: url('/media/images/global/ajax-loading--white.gif') no-repeat 50%;
}
/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */

#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
}
/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */

#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
  outline: 0;
}

#cboxSlideshow {
  position: absolute;
  top: -20px;
  right: 90px;
  color: $white;
}

#cboxPrevious {
  position: absolute;
  top: 50%;
  left: 5px;
  margin-top: -32px;
  background: url(#{$base-theme-path}/img/vendor/colorbox/controls.png) no-repeat;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}

#cboxPrevious:hover {
  background-position: bottom left;
}

#cboxNext {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -32px;
  background: url(#{$base-theme-path}/img/vendor/colorbox/controls.png) no-repeat top right;
  width: 28px;
  height: 65px;
  text-indent: -9999px;
}

#cboxNext:hover {
  background-position: bottom right;
}

#cboxClose {
  color: $color-off-black;
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: normal;
  font-size: 0; // hides 'close' text generated from plugin script
  text-align: right;
  @include breakpoint($portrait-up) {
    top: 16px;
    right: 19px;
  }
  &:before {
    @include svg-icon-inline-mask('close');
    content: '';
    background-color: $color-off-black;
    display: inline-block;
    width: 24px;
    height: 24px;
    @include breakpoint($portrait-up) {
      width: 36px;
      height: 36px;
    }
  }
}

// Custom "Dark" Theme
#colorbox.colorbox--dark {
  * {
    color: $white;
  }
  #cboxLoadedContent {
    background: none;
  }
  #cboxLoadedContent {
    padding: 35px 20px;
    @include breakpoint($portrait-up) {
      padding: 50px 30px;
    }
  }
  #cboxClose {
    top: 0;
    right: 20px;
    @include breakpoint($portrait-up) {
      right: 30px;
    }
  }
}
